.flex {
  display: flex;
}

.verification-code {
  border: 1px solid grey;
  width: 300px;
  border-radius: 4px;
  position: relative;
}

.verification-code input {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 0;
  text-align: center;
  font-size: 1.6rem;
}

[dir="ltr"] .verification-code input {
  border-right: 1px solid grey;
}

[dir="rtl"] .verification-code input {
  border-left: 1px solid grey;
}

.verification-code input:focus {
  outline: none;
  border: 2px solid #2467eb;
}

[dir="ltr"] .verification-code input:last-of-type:focus .verification-code {
  border-right: 0;
}

[dir="rtl"] .verification-code input:last-of-type:focus .verification-code {
  border-left: 0;
}

[dir="ltr"] .verification-code input:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

[dir="rtl"] .verification-code input:last-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

[dir="ltr"] .verification-code input:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

[dir="rtl"] .verification-code input:first-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.focus-container {
  border: 3px solid #2467eba3;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
}

[dir="ltr"] .focus-container {
  left: 0;
}

[dir="rtl"] .focus-container {
  right: 0;
}

[dir="ltr"] td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

[dir="rtl"] td:first-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

[dir="ltr"] td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

[dir="rtl"] td:last-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

table {
  border-collapse: collapse;
  width: 300px; /* 6 columns * 50px */
}

td {
  width: 50px;
  height: 50px;
  border: 1px solid #ccc; /* Light grey */
  text-align: center;
  color: #9b9292;
}

.verification-container .message {
  text-align: center;
}

.verification-container .back {
  color: #2467eba3;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.verification-container .back svg {
  width: 20px;
  height: 20px;
  color: #2467eba3;
}
