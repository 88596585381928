:root {
  --white: white;
  --black: black;
}

.w-layout-vflex {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.w-layout-hflex {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.body {
  color: #000;
  height: 100%;
  font-family: Open Sans, sans-serif;
}

.auth-page-title {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
}

.auth-page-title.smaller {
  font-size: 28px;
}

.password-eye-block {
  opacity: .5;
  cursor: pointer;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  justify-content: center;
  align-items: center;
  height: 55px;
  padding-left: 8px;
  padding-right: 8px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.password-eye-block:hover {
  opacity: .7;
}

.password-eye-block.checking-status-block {
  opacity: 1;
  padding-left: 8px;
  padding-right: 8px;
}

.password-eye-block.checking-status-block.hidden {
  display: none;
}

.external-auth-btn {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  cursor: pointer;
  border: .8px solid #a7a7a7;
  border-radius: 4px;
  width: 100%;
  padding: 15px 20px;
}

.external-auth-btn:hover {
  background-color: rgba(237, 237, 237, .64);
}

.div-block-35 {
  border: .5px solid #000;
  width: 0;
  height: 15px;
}

.auth-footer-links {
  opacity: .6;
}

.auth-footer-links:hover {
  opacity: .85;
}

.auth-input-group {
  width: 100%;
  display: none;
  position: relative;
}

.auth-input-group.visible {
  display: flex;
}

.btn-spinner-block {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
}

.btn-spinner-block.hidden {
  display: none;
}

.btn-spinner-block.dark-spinner-block {
  width: 18px;
}

.div-block-36 {
  border: .5px solid #a7a7a7;
  width: 100%;
}

.password-eye-icon {
  width: 20px;
}

.password-eye-icon.hidden {
  display: none;
}

.auth-footer {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
}

.external-auth-label {
  opacity: .7;
}

.auth-page-block {
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 100%;
  padding-top: 30px;
  overflow: auto;
}

.text-block-29 {
  opacity: .75;
}

.auth-page-main-content {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  align-items: center;
  width: 100%;
}

.auth-page-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.link-block {
  color: #000;
  font-size: 12px;
  text-decoration: none;
}

.auth-logo-img {
  width: 40px;
}

.auth-input {
  border-radius: 4px;
  height: 55px;
  margin-bottom: 0;
  padding-left: 20px;
  font-size: 16px;
}

.auth-input.password-field {
  padding-right: 40px;
}

.auth-redirect-block {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  width: 100%;
  font-size: 13px;
}

.auth-redirect-block.hidden {
  display: none;
}

.external-auth-block {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  width: 100%;
}

.external-auth-block.hidden {
  display: none;
}

.auth-seperation-block {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.auth-seperation-block.hidden {
  display: none;
}

.auth-form {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  display: flex;
}

.image-31 {
  width: 20px;
}

.auth-form-block {
  width: 100%;
  margin-bottom: 0;
}

.auth-submit-btn {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #fff;
  cursor: pointer;
  background-image: linear-gradient(135deg, #2467eb, rgba(36, 103, 235, .64) 52%);
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 55px;
  font-size: 15px;
  position: relative;
}

.auth-submit-btn:hover {
  background-color: #2467eb;
  background-image: none;
}

.auth-link-redirect {
  opacity: .75;
  color: #2467eb;
  text-decoration: none;
}

.auth-link-redirect:hover {
  opacity: 1;
}

.auth-link-redirect.mt-small {
  margin-top: 20px;
}

.submit-button-2 {
  display: none;
}

.text-block-30 {
  opacity: .55;
  font-size: 12px;
}

.external-auth-container {
  color: #000;
  width: 100%;
  text-decoration: none;
}

.text-error {
  color: #fff;
  text-align: center;
  background-color: #ff4e4e;
  width: 100%;
  margin-top: -5px;
  padding: 2px 8px;
  font-size: 13px;
  font-weight: 300;
}

.text-error.hidden {
  display: none;
}

.notification-close-icon {
  opacity: .7;
  width: 18px;
}

.notifications-block {
  z-index: 2147483647;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  padding: 15px;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.notification-close {
  cursor: pointer;
  border-radius: 3px;
  padding: 1px;
  position: absolute;
  top: 24px;
  bottom: auto;
  left: auto;
  right: 23px;
}

.notification-close:hover {
  background-color: rgba(30, 103, 235, .09);
}

.notification-header-title {
  color: #333;
  font-size: 16px;
}

.notification-body {
  margin-top: 8px;
  padding-left: 35px;
}

.notification-body-parag {
  color: #333;
  margin-bottom: 0;
  font-size: 14px;
}

.notification-header-block-icon {
  width: 20px;
}

.notification-header-block {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  align-items: center;
  width: 100%;
}

.notification-element {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  padding: 24px 25px 14px;
  display: none;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

.div-block-37 {
  border: .5px solid #a7a7a7;
  width: 100%;
}

.text-block-31, .auth-link-redirect-2 {
  opacity: .75;
}

.auth-link-redirect-2:hover {
  opacity: 1;
}

.auth-title-desc {
  opacity: .7;
  text-align: center;
  font-size: 13px;
}

.auth-title-desc.hidden {
  display: none;
}

.text-block-32 {
  opacity: .55;
  font-size: 12px;
}

.external-auth-container-2 {
  color: #000;
  width: 100%;
  text-decoration: none;
}

.status-icon {
  width: 20px;
}

.status-icon.hidden {
  display: none;
}

.top-header-question-link {
  text-decoration: none;
}

.top-header-question-link:hover {
  opacity: .8;
}

.top-header-closer-container {
  opacity: .7;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 100%;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.top-header-closer-container:hover {
  opacity: 1;
}

.top-header-icon-container {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 100%;
  position: relative;
}

.top-header-closer-icon {
  filter: invert();
  width: 20px;
}

.top-header-img {
  filter: invert();
  width: 16px;
}

.tooltip {
  z-index: 2147483647;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, .75);
  border: 1px solid #000;
  border-radius: 4px;
  flex-wrap: nowrap;
  flex: 0 auto;
  justify-content: center;
  align-self: auto;
  align-items: center;
  width: 320px;
  max-width: 390px;
  height: 30px;
  padding: 0 10px;
  font-size: 13px;
  display: flex;
  position: absolute;
  top: -34px;
  bottom: auto;
  left: auto;
  right: auto;
}

.tooltip.tooltip-trial {
  flex: none;
  height: 27px;
  display: none;
  top: 30px;
  left: -160px;
}

.site-top-header-container {
  grid-column-gap: 13px;
  grid-row-gap: 13px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.top-header-text {
  color: #fff;
  font-size: .85rem;
}

.top-header-text.pill {
  border: 1px solid #fff;
  border-radius: 15px;
  padding: 1px 10px;
}

.site-top-header {
  background-image: linear-gradient(135deg, #2467eb, rgba(36, 103, 235, .75) 65%);
  width: 100%;
  height: 35px;
  position: relative;
}

.modal-loader-block {
  z-index: 999;
  background-color: var(--white);
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.modal-loader-block.hidden {
  display: none;
  position: -webkit-sticky;
  position: sticky;
}

.modal-loader-content {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.modal-loader-icon {
  height: 45px;
}

.modal-loader-icon-box {
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
}

.heading {
  color: #888;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
}

.text-helper {
  color: #7e7e7e;
  font-size: 12px;
}

.text-helper.hidden {
  display: none;
}

@media screen and (max-width: 767px) {
  .tooltip.tooltip-trial {
    left: -80px;
  }
}

@media screen and (max-width: 479px) {
  .tooltip.tooltip-trial {
    width: 280px;
    max-width: 280px;
    height: 47px;
    display: none;
    left: -5px;
  }

  .top-header-text {
    font-size: .75rem;
  }

  .site-top-header {
    display: block;
  }
}


